@import '../../assets/styles/global.sass';

input[type="text"] {
    z-index: 5;
    height: 5vh;
    width: 100%;
    font-size: 18px;
    border-bottom: 2px solid #EBEBEB; }


.info-1, .info-2 {
    width: 90%;
    @media screen and (max-width: 750px) {
       display: none; }

    &-module {
        @include module; }

    &-alt {
        @include alt; } }


.info-2 {
    display: none;
    @media screen and (max-width: 750px) {
       display: block;
       width: 90%;
       height: 15vh; } }



.form-window-container {
    @include window(5);

    &-no {
        display: none; }

    &-back {
        margin-top: 10px;
        opacity: 0;
        transition: .5s;
        animation: show .5s 1;
        animation-fill-mode: forwards;
        animation-delay: 1s;
        width: 15%;
        height: 4vh;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        background: $whiteLight;
        box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
        border-radius: 20px;
        @media screen and (max-width: 1400px) {
            & {
                width: 25%; } }

        @media screen and (max-width: 760px) {
            & {
                width: 35%; } }

        @media screen and (max-width: 600px) {
            & {
                width: 55%; } }

        @media screen and (max-width: 500px) {
            & {
                width: 65%; } } }


    &-element {
        @include element(flex-start, 94vh, 60%, 0px 2px 6px rgba(0, 0, 0, 0.2));
        border-radius: 15px;
        opacity: 0;
        transition: .5s;
        animation: show .5s 1;
        animation-fill-mode: forwards;
        animation-delay: 1s;
        @media screen and (max-width: 1300px) {
            & {
                width: 90%; } }

        @media screen and (max-width: 700px) {
            & {
                height: 90vh; } }


        &-items {
            @include item(90%, 0%, 2%, column, flex-start);

            &-button {
                @include button($blue, $whiteLight, 160px, 0px);
                @media screen and (max-width: 1600px) {
                    & {
                        width: 160px;
                        margin-left: 50px; } } }

            &-cancel {
                background-color: $gray; }

            &-text {
                @include title; } }

        &-fields {
            witdh: 100%;
            height: 80%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            @media screen and (max-width: 750px) {
                & {
                    flex-direction: column;
                    align-items: center;
                    overflow-y: scroll;
                    margin-top: 2vh; } }

            &-col {
                width: 45%;
                height: 100%;
                margin: 0px 10px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: center;
                @media screen and (max-width: 750px) {
                    & {
                        width: 95%;
                        height: 110%; } }

                &-input-1 {
                    @include input(8vh);


                    &-title-1 {
                        color:#A1A1A1 {}
                        text-align: start; }

                    &-title-2 {
                        color: #A1A1A1;
                        text-align: start;
                        width: 90%;
                        margin-bottom: 10px; }


                    &-select, &-address {
                        background-color: #EBEBEB;
                        color: $dark;
                        height: 4vh;
                        width: 100%;
                        line-height: 4vh;
                        font-size: 18px;
                        border-radius: 6px;
                        outline: none;
                        padding-left: 10px; }

                    &-address {
                        width: 98%;
                        @media screen and (max-width: 1200px) {
                            font-size: 18px; }

                        @media screen and (max-width: 820px) {
                            font-size: 16px; } } }

                &-input-2 {
                    border-bottom: 2px solid #EBEBEB; }

                &-input-2, &-click-2 {
                    @include input(5vh, 25vh, 35vh, 45vh);
                    @media screen and (max-width: 1700px) {
                        & {
                            height: 6vh; } }

                    @media screen and (max-width: 750px) {
                        & {
                            height: 8vh; } }

                    @media screen and (max-width: 500px) {
                        & {
                            height: 9vh; } }

                    @media screen and (max-width: 400px) {
                        & {
                            height: 10vh; } }

                    &-ready, &-sum {
                        width: 30%;
                        display: flex;
                        flex-direction: column;
                        align-itmes: center;
                        justify-content: center; }

                    &-details {
                        height: 4vh;
                        width: 100%;
                        color: #A1A1A1;
                        font-size: 18px;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        animation: show 1s linear; }

                    &-text {
                        width: 85%; }

                    &-field {
                        @include field();
                        @media screen and (max-width: 1700px) {
                            & {
                                height: 7vh; } }

                        @media screen and (max-width: 750px) {
                            & {
                                height: 9vh; } }

                        @media screen and (max-width: 500px) {
                            & {
                                height: 10vh; } }

                        @media screen and (max-width: 400px) {
                            & {
                                height: 12vh; } }

                        &-check {
                            width: 70%;
                            padding-left: 10px;
                            color: $dark;
                            font-family: Roboto;
                            font-style: normal;
                            font-weight: normal;
                            font-size: 18px;
                            line-height: 18px;
                            text-align: start;
                            @media screen and (max-height: 840px) {
                                & {
                                    width: 85%; } } }

                        &-amount {
                            display: flex;
                            width: 140px;
                            justify-content: space-between;
                            @media screen and (max-width: 1700px) {
                                & {
                                    width: 25%; } }

                            @media screen and (max-width: 1600px) {
                                & {
                                    width: 30%; } }

                            @media screen and (max-width: 1200px) {
                                & {
                                    width: 40%; } }

                            @media screen and (max-width: 600px) {
                                & {
                                    width: 50%; } }

                            &-operation {
                                width: 45px;
                                height: 25px;
                                line-height: 25px;
                                cursor: pointer;
                                background-color: $white;
                                text-align: center;
                                color: $dark; }

                            &-value {
                                color: $dark; } } } } } } } }


@keyframes show {
    0% {
        opacity: 0; }
    100% {
        opacity: 1; } }
