@import '../../assets/styles/global.sass';


.pay-window-container {
    @include window(10);
    animation: show 1s linear;

    &-no {
        display: none; }

    &-back {
        position: absolute;
        margin-bottom: 40px;
        opacity: 0;
        transition: .5s;
        animation: show .5s 1;
        animation-fill-mode: forwards;
        animation-delay: 1s;
        width: 15%;
        height: 4vh;
        display: flex;
        justify-content: center;
        align-items: center;
        align-self: center;
        cursor: pointer;
        background: $whiteLight;
        box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
        border-radius: 20px;

        @media screen and (max-width: 1400px) {
            & {
                width: 25%; } }

        @media screen and (max-width: 760px) {
            & {
                width: 35%; } }

        @media screen and (max-width: 600px) {
            & {
                width: 55%; } }

        @media screen and (max-width: 500px) {
            & {
                width: 65%; } } }

    &-element {
        @include element(center, 65vh, 40%, 0 0 30px $black); }

    &-element-2, &-element-4 {
        @include element(flex-start, 60vh, 40%, 0 0 30px $black); }

    &-element-5 {
        @include element(flex-start, 65vh, 35%, 0 0 30px $black); }

    &-element, &-element-5, &-element-4, &-element-2 {
        @media screen and (max-width: 1600px) {
            & {
                width: 40%; } }

        @media screen and (max-width: 1200px) {
            & {
                width: 40%;
                height: 70vh; } }

        @media screen and (max-width: 800px) {
            & {
                height: 75vh;
                width: 50%; } }

        @media screen and (max-width: 700px) {
            & {
                width: 60%; } }

        @media screen and (max-width: 600px) {
            & {
                height: 80vh;
                width: 90%; } }

        @media screen and (max-width: 400px) {
            & {
                height: 85vh; } }

        @media screen and (max-width: 340px) {
            & {
                height: 95vh; } }


        &-click {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;

            &-button-1 {
                @include button($blue, $whiteLight, 250px);
                animation: show 2s linear; }

            &-button-2 {
                @include button($white, $dark, 250px);
                margin-bottom: 20px; }

            &-wait-1 {
                width: 60px;
                height: 60px;
                border-radius: 50%;
                border: 10px solid $blue;
                border-top: 10px solid $black;
                animation: circling 2s infinite linear; } }

        &-items {
            @include item(90%, 0%, -5%, column, flex-end);

            &-text {
                @include title;

                &-description {
                    font-family: Roboto;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 15px;
                    line-height: 22px;
                    color: $dark; } } } } }

@keyframes circling {
    0% {
        transform: rotate(0deg); }

    100% {
        transform: rotate(360deg); } }


@keyframes show {
    0% {
        opacity: 0; }
    100% {
        opacity: 1; } }
