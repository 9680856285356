@import '../../assets/styles/global.sass';


.confirm-window-container {
    @include window(10);
    animation: show 1s linear;

    &-no {
        display: none; }


    &-element {
        @include element(space-around, 65vh, 35%, 0 0 30px $black);

        @media screen and (max-width: 1600px) {
            & {
                width: 40%;
                height: 70vh; } }

        @media screen and (max-width: 1200px) {
            & {
                width: 40%;
                height: 75vh; } }

        @media screen and (max-width: 800px) {
            & {
                width: 50%; } }

        @media screen and (max-width: 700px) {
            & {
                width: 60%; } }

        @media screen and (max-width: 500px) {
            & {
                width: 95%;
                height: 85vh; } }

        @media screen and (max-width: 350px) {
            & {
                height: 90vh; } }

        @media screen and (max-height: 640px) {
            & {
                height: 95vh; } }



        &-click {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;

            &-button-1 {
                @include button($blue, $whiteLight, 250px);
                animation: show 2s linear; }

            &-button-2 {
                @include button($white, $dark, 250px); }

            &-wait-1 {
                width: 60px;
                height: 60px;
                border-radius: 50%;
                border: 10px solid $blue;
                border-top: 10px solid $black;
                animation: circling 2s infinite linear; } }


        &-items {
            @include item(90%, 0%, -5%, column, flex-end);

            &-text {
                @include title;

                &-description {
                    font-family: Roboto;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 15px;
                    line-height: 22px;
                    color: $dark; } } } } }

@keyframes circling {
    0% {
        transform: rotate(0deg); }

    100% {
        transform: rotate(360deg); } }

@keyframes show {
    0% {
        opacity: 0; }
    100% {
        opacity: 1; } }
