@import '../../assets/styles/global.sass';


.marker-window-container {
    @include window(10);
    animation: show 1s linear;

    &-no {
        display: none; }


    &-element {
        @include element(space-around, 35vh, 35%, 0 0 30px $black);

        @media screen and (max-width: 1600px) {
            & {
                width: 40%;
                height: 40vh; } }

        @media screen and (max-width: 1200px) {
            & {
                width: 40%;
                height: 45vh; } }

        @media screen and (max-width: 800px) {
            & {
                width: 50%; } }

        @media screen and (max-width: 700px) {
            & {
                width: 60%; } }

        @media screen and (max-width: 500px) {
            & {
                width: 95%;
                height: 65vh; } }

        @media screen and (max-width: 350px) {
            & {
                height: 80vh; } }

        @media screen and (max-height: 840px) {
            & {
                height: 75vh; } }

        @media screen and (max-height: 640px) {
            & {
                height: 90vh; } }



        &-click {
            width: 100%;
            margin-bottom: 10x;
            display: flex;
            justify-content: space-around;

            &-button-1 {
                @include button($blue, $whiteLight, 150px); }

            &-button-2 {
                @include button($white, $dark, 150px); } }


        &-items {
            @include item(90%, 0%, 2%, column, flex-start);

            &-text {
                @include title;

                &-description {
                    font-family: Roboto;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 15px;
                    line-height: 22px;
                    color: $gray; } } } } }

@keyframes show {
    0% {
        opacity: 0; }
    100% {
        opacity: 1; } }
