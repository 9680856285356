// *VARIABLES*
$blue: #14BEF1;
$black: rgba(0, 0, 0, 0.2);
$dark: #000000;
$gray: #A1A1A1;
$white: #EBEBEB;
$whiteLight: #ffffff;
$confirm: #80E800;
$cancel: #FF4C00;

// *MIXINS*

@mixin window($index) {
    height: 100vh;
    width: 100%;
    position: absolute;
    z-index: $index;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; }

@mixin element($justifyContent, $height, $width, $boxShadow) {
    box-shadow: $boxShadow;
    width: $width;
    height: $height;
    background-color: $whiteLight;
    display: flex;
    flex-direction: column;
    justify-content: $justifyContent;
    align-items: center; }

@mixin item($width, $marginTop, $marginBottom, $flexDirection, $justifyContent) {
    width: $width;
    margin-top: $marginTop;
    margin-bottom: $marginBottom;
    display: flex;
    flex-direction: $flexDirection;
    justify-content: $justifyContent;
    align-items: center; }

@mixin title {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 28px; }

@mixin field {
    height: 5vh;
    width: 100%;
    color: #A1A1A1;
    font-size: 18px;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between; }

@mixin module {
    height: 8vh;
    display: flex;
    align-items: center;
    margin-top: 10px; }

@mixin alt {
    font-family: Roboto;
    font-style: normal;
    width: 70%;
    margin-left: 20px;
    font-weight: normal;
    font-size: 15px;
    line-height: 15px;
    color: #A1A1A1;
    text-align: start; }

@mixin button($backgroundColor, $color, $width, $borderRadius: 25px) {
    width: $width;
    height: 50px;
    cursor: pointer;
    background-color: $backgroundColor;
    border-radius: $borderRadius;
    color: $color;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center; }

@mixin input($height, $mediaHeight_1: 10vh, $mediaHeight_2: 15vh, $mediaHeight_3: 15vh) {
    width: 90%;
    height: $height;
    background-color: $whiteLight;

    @media screen and (max-width: 750px) {
        height: $mediaHeight_1; }

    @media screen and (max-width: 500px) {
        height: $mediaHeight_2; }

    @media screen and (max-width: 400px) {
        height: $mediaHeight_3; } }
