@import '../../assets/styles/global.sass';

.wait-window-container {
    @include window(15);

    &-element {
        @include element(space-around, 40vh, 35%, 0 0 30px $black);

        @media screen and (max-width: 1200px) {
            & {
                width: 40%;
                height: 45vh; } }

        @media screen and (max-width: 800px) {
            & {
                width: 50%; } }

        @media screen and (max-width: 700px) {
            & {
                width: 65%;
                height: 45vh; } }

        @media screen and (max-width: 600px) {
            & {
                height: 55vh; } }

        @media screen and (max-width: 500px) {
             & {
                height: 60vh;
                width: 90%; } }

        @media screen and (max-width: 400px) {
             & {
                height: 70vh;
                width: 98%; } }

        @media screen and (max-width: 410px) {
            & {
                width: 98%; } } } }
