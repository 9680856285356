body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  overflow: hidden;
}

h1, h2, h3 {
  text-align: center;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

span, label, input, select, form {
  display: block;
}

form {
  display: flex;
}

div, span, select, option {
  outline: none;
}

select, input[type="tel"] {
  height: 100%;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 42px;
}

select {
  cursor: pointer;
  /* max-height: 15vh;
  overflow-y: auto; */
}

span {
  line-height: 150%;
  text-align: center;
}

label {
  margin-bottom: 10px;
  color: #575656;
  font-weight: bolder;
}

select {
  color: #27B0F6;
  width: 100px;
}

input[type="tel"] {
  width: 200px
}

input[type="checkbox"] {
  width: 20px;
  height: 20px;
}

input, select {
  outline: none;
  border: none;
  background-color: #ffffff;
}

.error-span {
  color: red
}

.no-error {
  visibility: hidden;
}