@import '../../assets/styles/global.sass';

.cancel-window-container {
    @include window(15);

    &-no {
        display: none; }

    &-element {
        @include element(space-around, 60vh, 35%, 0 0 30px $black);

        @media screen and (max-width: 1200px) {
            & {
                width: 40%;
                height: 65vh; } }

        @media screen and (max-width: 800px) {
            & {
                width: 50%; } }

        @media screen and (max-width: 700px) {
            & {
                width: 65%;
                height: 70vh; } }

        @media screen and (max-width: 500px) {
             & {
                height: 75vh;
                width: 90%; } }

        @media screen and (max-width: 400px) {
             & {
                height: 80vh;
                width: 98%; } }

        @media screen and (max-width: 410px) {
            & {
                width: 98%; } }


        &-items-button {
            @include button($blue, $whiteLight, 240px); } } }
