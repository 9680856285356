@import '../../assets/styles/global.sass';

.greeting-window-container {
    @include window(15);
    animation: show 2s linear;

    &-element {
        @include element(space-around, 55vh, 35%, 0 0 30px $black);

        @media screen and (max-width: 1200px) {
            & {
                width: 40%;
                height: 55vh; } }

        @media screen and (max-width: 800px) {
            & {
                width: 50%; } }

        @media screen and (max-width: 700px) {
            & {
                width: 65%;
                height: 60vh; } }

        @media screen and (max-width: 600px) {
            & {
                height: 65vh; } }

        @media screen and (max-width: 500px) {
             & {
                height: 70vh;
                width: 90%; } }

        @media screen and (max-width: 400px) {
             & {
                width: 98%; } }

        @media screen and (max-width: 410px) {
            & {
                width: 98%; } }

        @media screen and (max-height: 640px) {
            & {
                height: 75vh; } }

        @media screen and (max-height: 520px) {
            & {
                height: 85vh; } }

        @media screen and (max-height: 470px) {
            & {
                height: 95vh; } }


        &-items {
            @include item(90%, 5%, 2%, row, center);

            &-text {
                @include title; }

            &-tel {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                &-gray {
                    color: gray; } }

            &-button {
                @include button($blue, $whiteLight, 200px);

                &-no {
                    display: none; } } } } }

@keyframes show {
    0% {
        opacity: 0; }
    100% {
        opacity: 1; } }
